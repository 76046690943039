import { createContext, type ReactNode, useContext } from 'react'

const LocaleContext = createContext<string>('en-US')

export function LocaleProvider({
  children,
  locale,
}: {
  children: ReactNode
  locale: string
}) {
  return (
    <LocaleContext.Provider value={locale}>{children}</LocaleContext.Provider>
  )
}

export default function useLocale() {
  const context = useContext(LocaleContext)

  if (!context) {
    throw new Error('useLocale must be used within <LocaleContext.Provier />')
  }

  return context
}
