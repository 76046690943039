import { Form, Link, useSubmit } from '@remix-run/react'
import { useRef } from 'react'
import { useUser } from '#app/hooks/user.hooks.ts'
import { Button } from '../ui/button.tsx'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu.tsx'
import { CurrentUserAvatar } from './user-avatar.tsx'

export function UserDropdown() {
  const user = useUser()
  const submit = useSubmit()
  const formRef = useRef<HTMLFormElement>(null)

  if (user == null) {
    return null
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button className="rounded-full" variant="ghost" size="icon">
          <CurrentUserAvatar />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuPortal>
        <DropdownMenuContent align="end">
          <DropdownMenuItem asChild>
            <Link
              className="w-full"
              prefetch="intent"
              to={`/user/${user.username}`}
            >
              Profile
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem
            // this prevents the menu from closing before the form submission is completed
            onSelect={event => {
              event.preventDefault()
              submit(formRef.current)
            }}
          >
            <Form action="/logout" method="POST" ref={formRef}>
              Logout
            </Form>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  )
}
