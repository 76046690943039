import { Link, useMatches, useRouteLoaderData } from '@remix-run/react'
import { useMemo } from 'react'
import husthereLogo from '#app/assets/husthere-logo-beta.png'
import { type loader as rootLoader } from '#app/root.tsx'
import { Button } from '../ui/button.tsx'
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from '../ui/navigation-menu.tsx'
import { UserDropdown } from '../user-profile/user-dropdown.tsx'
import { UsersSearch } from './users-search.tsx'

export function NavigationHeader() {
  const data = useRouteLoaderData<typeof rootLoader>('root')

  let user = undefined
  if (data != null && data.user != null) {
    user = data.user
  }

  const matches = useMatches()
  const isOnboardingRoute = useMemo(
    () =>
      matches.some(
        match =>
          match.pathname.startsWith('/login') ||
          match.pathname.startsWith('/signup'),
      ),
    [matches],
  )
  const isUserSearchRoute = useMemo(
    () => matches.some(match => match.pathname.startsWith('/user/search')),
    [matches],
  )

  return (
    <header className="container border-b border-black">
      <nav>
        <div className="flex flex-wrap items-center justify-between gap-4 sm:flex-nowrap md:gap-8">
          <Link to="/" className="order-1">
            <img
              className="mb-5 mt-7 h-7 object-cover"
              src={husthereLogo}
              alt=""
            />
          </Link>
          <NavigationMenu className="order-4 mb-4 max-w-none sm:order-2 sm:mb-0 sm:max-w-max">
            <NavigationMenuList>
              <NavigationMenuItem>
                <NavigationMenuLink
                  className={navigationMenuTriggerStyle()}
                  href="/itinerary"
                >
                  Itinerary
                </NavigationMenuLink>
              </NavigationMenuItem>
            </NavigationMenuList>
          </NavigationMenu>
          <div className="order-2 ml-auto max-w-sm flex-1 sm:order-3" />
          <div className="order-3 flex items-center gap-4 sm:order-4">
            {!isUserSearchRoute && (
              <UsersSearch searchQuery={data?.searchUserQuery} />
            )}
            {user ? (
              <UserDropdown />
            ) : (
              !isOnboardingRoute && (
                <>
                  <Button asChild variant="ghost" size="sm">
                    <Link to="/login">Log In</Link>
                  </Button>
                  <Button asChild variant="default" size="sm">
                    <Link to="/signup">Sign Up</Link>
                  </Button>
                </>
              )
            )}
          </div>
        </div>
      </nav>
    </header>
  )
}
