import { getEnv } from '@harta-io/env'
import {
  json,
  type HeadersFunction,
  type LinksFunction,
  type MetaFunction,
  type LoaderFunctionArgs,
} from '@remix-run/node'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from '@remix-run/react'
import { withSentry } from '@sentry/remix'
import { IKContext } from 'imagekitio-react'
import mapboxStyleSheetUrl from 'mapbox-gl/dist/mapbox-gl.css?url'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { HoneypotProvider } from 'remix-utils/honeypot/react'
import { GeneralErrorBoundary } from './components/error-boundary.tsx'
import { NavigationHeader } from './components/nav/navigation-header.tsx'
import { HusthereProgress } from './components/progress-bar.tsx'
import { HusthereToaster } from './components/toaster.tsx'
import { parseAcceptLanguage } from './helpers/locale/index.ts'
import { LocaleProvider } from './hooks/locale.hooks.tsx'
import fontStyleSheetUrl from './styles/font.css?url'
import markdownViewerStyleSheetUrl from './styles/markdown-viewer.css?url'
import tailwindStyleSheetUrl from './styles/tailwind.css?url'
import { ClientHintCheck, getHints } from './utils/client-hints.tsx'
import { honeypot } from './utils/honeypot.server.ts'
import { getDomainUrl, combineHeaders } from './utils/misc.ts'
import { useNonce } from './utils/nonce-provider.ts'
import { getToast } from './utils/toast.server.ts'

export const links: LinksFunction = () => {
  return [
    // Preload CSS as a resource to avoid render blocking
    { rel: 'preload', href: fontStyleSheetUrl, as: 'style' },
    { rel: 'preload', href: tailwindStyleSheetUrl, as: 'style' },
    { rel: 'preload', href: markdownViewerStyleSheetUrl, as: 'style' },
    { rel: 'preload', href: mapboxStyleSheetUrl, as: 'style' },
    {
      rel: 'manifest',
      href: '/site.webmanifest',
      crossOrigin: 'use-credentials',
    } as const, // necessary to make typescript happy
    //These should match the css preloads above to avoid css as render blocking resource
    { rel: 'stylesheet', href: fontStyleSheetUrl },
    { rel: 'stylesheet', href: tailwindStyleSheetUrl },
    { rel: 'stylesheet', href: markdownViewerStyleSheetUrl },
    { rel: 'stylesheet', href: mapboxStyleSheetUrl },
  ].filter(Boolean)
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
    { title: data ? 'Husthere' : 'Error | Husthere' },
    { name: 'description', content: 'Your own travel assistant' },
  ]
}

export async function loader({ request, context }: LoaderFunctionArgs) {
  const language = parseAcceptLanguage(
    request.headers.get('Accept-Language') as string,
  )
  const { toast, headers: toastHeaders } = await getToast(request)
  const honeyProps = honeypot.getInputProps()

  const url = new URL(request.url)
  const searchUserQuery = url.searchParams.get('q')

  return json(
    {
      language,
      user: context.user,
      anonymousUser: context.anonymousUser,
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
      },
      ENV: getEnv(),
      toast,
      honeyProps,
      searchUserQuery,
    },
    {
      headers: combineHeaders(toastHeaders),
    },
  )
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    'Server-Timing': loaderHeaders.get('Server-Timing') ?? '',
  }
  return headers
}

function Document({
  children,
  nonce,
  env = {},
  lang,
}: {
  children: React.ReactNode
  nonce: string
  env?: Record<string, string>
  lang: string
}) {
  const language = lang.split('-')[0]

  // [HAR-274]: force light mode, we will explore dark mode at a later time
  return (
    <html lang={language} className="light h-full overflow-x-hidden">
      <head>
        <ClientHintCheck nonce={nonce} />
        <Meta />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Links />
      </head>
      <body className="bg-background text-foreground h-full w-full">
        <LocaleProvider locale={lang}>{children}</LocaleProvider>
        <script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`,
          }}
        />
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
      </body>
    </html>
  )
}

function App() {
  const data = useLoaderData<typeof loader>()
  const nonce = useNonce()

  return (
    <Document nonce={nonce} env={data.ENV} lang={data.language}>
      <div className="flex h-full w-full flex-col justify-between">
        <NavigationHeader />
        <div className="my-4 h-0 flex-1">
          <DndProvider backend={HTML5Backend}>
            <Outlet />
          </DndProvider>
        </div>
      </div>
      <HusthereToaster toast={data.toast} />
      <HusthereProgress />
    </Document>
  )
}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>()
  return (
    <HoneypotProvider {...data.honeyProps}>
      <IKContext
        urlEndpoint={ENV.MEDIA_CDN_URL}
        publicKey={ENV.MEDIA_CDN_PUBLIC_KEY}
        transformationPosition="path"
      >
        <App />
      </IKContext>
    </HoneypotProvider>
  )
}

export default withSentry(AppWithProviders)

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce()

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document nonce={nonce} lang="en-US">
      <GeneralErrorBoundary />
    </Document>
  )
}
